import React from "react"
import { Link } from "gatsby"

const RecentBlogs = ({title='Blog', moduleGray=false}) => {

  let items = []

  for (let i=0; i<3; i++) {
    items.push(
      <div className="col-md-4">
        <Link to={'/blog-detail'} className="blogList" data-sal="slide-up" data-sal-delay={100 * i}>
          <div className="blogList-img">
            <img src={require('../../static/images/blog1.jpg')} alt="Blog Desc"/>
            <span>Design</span>
          </div>
          <div className="blogList-desc">
            <h3>10 tips for drawing with coloured pencils</h3>
            <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua Ut enim...
            </div>
          </div>
        </Link>
      </div>
    )
  }

  return (
    <section id="blog" className={`module blog module ${ moduleGray ? "module-gray":"" } `}>
      <div className="container">

        <div className="module-header" data-sal="slide-up">
          <h2 className="module-title">{title}</h2>
          <div className="module-line"></div>
        </div>

        <div className="row">
          {
            items
          }
          <div className="col-md-12">
            <Link className="blog-viewMore" to={'/blog'} data-sal="slide-up">VIEW MORE</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RecentBlogs