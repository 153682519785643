import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RecentBlogs from "../components/recent_blogs"

const BlogDetail = ({ data, location }) => {

  let content = (
    <section className="blog-detail">
      <div className="container">
        <div className="blog-detail-banner wow fadeIn">
          <img src={require("../../static/images/blog-banner.jpg")} alt="Blog Desc" />
        </div>
        <div className="blog-detail-contents wow fadeInUp">
          <h2>The single greatest gift to get your friend who always travels</h2>
          <div className="blog-detail-about clearfix">
            <div className="media float-left align-items-center">
              <div className="media-left mr-3">
                <img src={require("../../static/images/avatar.jpg")} className="media-object" alt="avatar" />
              </div>
              <div className="media-body">
                <h4 className="media-heading">Infinicube</h4>
                <p>12 Jan 2019</p>
              </div>
            </div>
            <div className="blog-detail-category float-right">
              tavel
            </div>
          </div>
          <div className="blog-detail-content">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.</p>

            <h3>Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC</h3>

            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam
              rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
              explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia
              consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui
              dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora
              incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

            <img src={require("../../static/images/blog-detail.jpg")} alt="Blog Desc" />

              <p>Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut
                aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse
                quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>

              <h3>1914 translation by H. Rackham</h3>

              <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born
                and I will give you a complete account of the system, and expound the actual teachings of the great
                explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids
                pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure
                rationally encounter consequences that are extremely painful. </p>

              <blockquote> Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because
                it is pain, but because occasionally circumstances occur in which toil and pain can procure him some
                great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise,
                except to obtain some advantage from it?
              </blockquote>

              <h3>1914 translation by H. Rackham</h3>

              <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born
                and I will give you a complete account of the system, and expound the actual teachings of the great
                explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids
                pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure
                rationally encounter consequences that are extremely painful. </p>

          </div>
        </div>
      </div>
    </section>
  )

  return (
    <Layout location={location}>
      <SEO title="Single Blog" />
      {content}
      <RecentBlogs title="Recent Posts" moduleGray={true} />
    </Layout>
  )

}

export default BlogDetail